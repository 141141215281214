/* merged theme.css with twistedroad.css into main.css */

/* Global
-------------------------------------------------- */

/* Color palette */
.text-lightest {
  color: #d1a87c;
}

.text-light {
  color: #a24b10;
}

.text-medium {
  color: #72300a;
}

.text-dark {
  color: #571d04;
}

.text-darkest {
  color: #3f1405;
}

.bg-lightest {
  color: #d1a87c;
}

.bg-light {
  color: #a24b10;
}

.bg-medium {
  color: #72300a;
}

.bg-dark {
  color: #571d04;
}

.bg-darkest {
  color: #3f1405;
}

html {
  margin: 0;
  font-size: 1.25em;
}

body {
  position: relative;
  line-height: 20px;
  font-family: "felt-tip-roman", sans-serif !important;
  -webkit-font-smoothing: antialiased;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body .h1,
body .h2,
body .h3,
body .h4,
body .h5,
body .h6 {
  font-family: "felt-tip-roman", sans-serif !important;
  font-weight: bold;
  color: inherit;
  text-rendering: optimizelegibility;
}

body .pull_top {
  padding-top: 0px !important;
}

/* try get these to work on the navbar dropdown list items */
/* no effect so far */
a:link {
  color: #d1a87c;
}

a:visited {
  color: #d1a87c;
}

a:hover {
  color: #a24b10;
}

a:active {
  color: #a24b10;
}

/* page section headers */
.section_header {
  padding-top: 5em;
  margin-bottom: 3em;
  color: #571d04;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.5em;
  border-bottom: 2px solid #571d04;
}

.section_header_dark {
  padding-top: 5em;
  margin-bottom: 3em;
  color: #571d04;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.5em;
  /* border-bottom: 1px solid #571D04; */
}

/* carousel ----------------------------------------------------------------- */
.carousel {
  padding-top: 5em;
  padding-bottom: 2em;
  background-image: url("https://cdn.twistedroad.org/images/redwoodsfog.jpg");
  height: auto;
  width: 100%;
}

.albumcarouselalbum {
  padding: 1em;
  width: 300px !important;
}

/* all list pages - albums, media, news, sites, etc. */
.item {
  border-radius: 0.15em;
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0, #3f1405),
    color-stop(1, #a24a10)
  );
  background-image: -o-linear-gradient(right bottom, #3f1405 0%, #a24a10 100%);
  background-image: -moz-linear-gradient(
    right bottom,
    #3f1405 0%,
    #a24a10 100%
  );
  background-image: -webkit-linear-gradient(
    right bottom,
    #3f1405 0%,
    #a24a10 100%
  );
  background-image: -ms-linear-gradient(right bottom, #3f1405 0%, #a24a10 100%);
  background-image: linear-gradient(to right bottom, #3f1405 0%, #a24a10 100%);
}

.item h3 {
  color: #d1a87c;
  font-size: 1.5em;
}

.item h4 {
  color: #d1a87c;
  font-size: 1.2em;
}

/* masonry -------------------------------------------------- */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.masonry {
  max-width: 100%;
}

.masonry .album-tile {
  float: left;
  width: 300px;
  height: 300px;
  margin: 5px;
  border: 1px solid black;
}

/* home/index/BigNavButtons ------------------------------------------------- */
.index {
  background-color: #d1a87c;
}

.btn_desc {
  color: #3f1405;
  font-size: 1.5em;
  padding: 0.25em 0.25em 1.5em;
}

/* search on discography page --------------------------------------------------- */
.search {
  background-color: #d1a87c;
}

.searchyearartistalbum {
  background-color: #3f1405;
  color: #d1a87c;
}

.searchalbumbox {
  border-color: #3f1405;
  border-style: solid;
  border-width: 2px;
}

/* all form inputs */
input[type="text"],
input[type="password"],
input[type="submit"],
textarea {
  font-family: "felt-tip-roman", sans-serif;
}

/* discography ------------------------------------------------ */
.discography {
  background-color: #d1a87c;
}

/* sort & filter controls */
.sort_filter {
  height: 4em;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0.15em;
  background-color: #3f1405;
}

.sort_filter_btn {
  background-color: #d1a87c;
  color: #571d04;
  font-size: 1.25em;
  line-height: 1.25em;
  border-color: #000;
  border-radius: 0.15em;
}

.sort_filter_btn:hover,
.sort_filter_btn:active,
.sort_filter_btn:focus {
  background: #571d04;
  color: #d1a87c;
  border-color: #000;
}

.sort_filter_search {
  background-color: #d1a87c;
  color: #571d04;
  font-size: 1.25em;
  line-height: 1.25em;
  width: 250px;
  padding: 5px;
}

.albumbox {
  border-radius: 0.15em;
  background-color: #3f1405;
  color: #d1a87c;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
  margin-bottom: 15px;

  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0, #3f1405),
    color-stop(1, #a24a10)
  );
  background-image: -o-linear-gradient(right bottom, #3f1405 0%, #a24a10 100%);
  background-image: -moz-linear-gradient(
    right bottom,
    #3f1405 0%,
    #a24a10 100%
  );
  background-image: -webkit-linear-gradient(
    right bottom,
    #3f1405 0%,
    #a24a10 100%
  );
  background-image: -ms-linear-gradient(right bottom, #3f1405 0%, #a24a10 100%);
  background-image: linear-gradient(to right bottom, #3f1405 0%, #a24a10 100%);
}

.albumart {
  margin: 25px;
  border-radius: 0.15em;
  cursor: pointer;
}

.albumtitleartistyear {
  color: #d1a87c;
  font-weight: 300;
  font-size: 1.5em;
  line-height: 1.5em;
  margin-top: 25px;
}

.albumdetail {
  text-align: left;
  margin-left: 25px;
  margin-top: 10px;
  /* height: 700px; */
  color: #d1a87c;
  font-weight: 300;
  font-size: 1.25em;
  line-height: 1.25em;
  /* display: inline-block; */
}

/* albumbox animation*/
/* try to reuse for all ng-views */
.my-show-hide-animation.ng-hide-add,
.my-show-hide-animation.ng-hide-remove {
  -webkit-transition: 0.75s linear all;
  transition: 0.75s linear all;
  display: block !important;
  opacity: 1;
}

.my-show-hide-animation.ng-hide {
  opacity: 0;
}

/* media --------------------------------------------------- */
.media {
  background-color: #d1a87c;
}

/* news ---------------------------------------------------- */
.news {
  background-color: #d1a87c;
}

/* neil ---------------------------------------------------- */
.neil {
  background-color: #d1a87c;
}

.bio {
  margin-bottom: 60px;
}

.bio-text {
  color: #571d04;
  font-size: 1.4em;
}

.copyright {
  font-size: 0.8em;
}

/* more barn ------------------------------------------------ */
.morebarn {
  background-color: #d1a87c;
}

/* lyrics --------------------------------------------------- */
.lyrics {
  background-color: #3f1405;
}

.lyrics .section_header {
  color: #d1a87c;
  border-bottom: 1px solid #d1a87c;
}

.lyrics .twistedroad {
  font-size: 1.5em;
  line-height: 1.25em;
  color: #d1a87c;
}

/* contact -------------------------------------------------- */
.contact {
  background-color: #3f1405;
}

/* about ---------------------------------------------------- */
.about {
  color: #a24b10; /* text-light */
  background-color: #3f1405;
}

.about .section_header {
  color: #d1a87c; /* text-lightest */
  border-bottom: 1px solid #d1a87c;
}

.about h4 {
  font-size: 1.5em;
  line-height: 1.5em;
}

.about p {
  color: #d1a87c; /* text-lightest */
  font-size: 1.5em;
  line-height: 1.25em;
}

/* Navbar
-------------------------------------------------- */
.navbar {
  background: #3f1405;
  padding: 10px 0px 10px 0px;
}

.navbar .container {
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-transition: margin 0.2s ease-in-out;
  -moz-transition: margin 0.2s ease-in-out;
  transition: margin 0.2s ease-in-out;
}

.navbar .container .navbar-header .navbar-brand {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  margin-left: -20px;
  padding: 10px 20px 10px;
}

.navbar .container .collapse .nav .dropdown .caret {
  margin-bottom: 4px;
}

.navbar .container .collapse .nav .dropdown .dropdown-menu {
  color: #571d04;
  background: #3f1405;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 0.25em;
  margin-top: 0px;
}

.navbar .dropdown-menu {
  background: #3f1405;
}

.navbar .dropdown-menu > li > a {
  color: #571d04;
  background: #a24b10;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 0.25em;
  margin-top: 0px;
}

/* try get these to work on the navbar dropdown list items */
/* no effect so far */
.dropdown-menu > li > a:link {
  color: #d1a87c;
  background-color: #3f1405;
}

.dropdown-menu > li > a:visited {
  color: #d1a87c;
  background-color: #3f1405;
}

.dropdown-menu > li > a:hover {
  color: #a24b10;
  background-color: #3f1405;
}

.dropdown-item {
  color: #a24b10;
  background-color: #3f1405;
}

/* Footer
-------------------------------------------------- */
#footer {
  padding-top: 2em;
  background-color: #252528;
  color: #a6a6a7;
  text-align: center;
  font-size: 1.25em;
  line-height: 1.5em;
  font-style: italic;
  min-height: 150px;
}
